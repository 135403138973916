import React, { Component } from "react";
import { strings } from "../../../../resources/GlobalStrings";
import "../../../../css/login.css";
import SimpleReactValidator from "simple-react-validator";

class ConfirmOTPEmailView extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      email: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleOTPLogin = event => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    if (this.state.email !== "") {
      this.props.handleOTPLogin(this.state.email);
    }
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{
            margin: "auto 0",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className="back-button-container">
            <div className="back-button" onClick={this.props.backToLoginForm}>
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: "150%", color: "grey" }}
              />
            </div>
          </div>
          <div
            className="col-lg-8 col-md-8 col-sm-12 col-12 loginbox"
            style={{ margin: "auto", position: "absolute" }}
          >
            {/* <div className="loginbox-middle">
                <hr className="hr-text" data-content="OR LOGIN WITH EMAIL" />
              </div> */}
            <div className="loginbox-bottom">
              <form action="" noValidate>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}
                >
                  Login Using OTP
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#928d8d",
                    padding: "1%"
                  }}
                >
                  Please enter associated Email ID
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.EMAIL_ID} </div>

                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="inputBlock"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Email",
                      this.state.email,
                      "required"
                    )}
                  </div>
                </div>
                <div className="inputbox" style={{ display: "flex" }}>
                  <button
                    className="login-button"
                    onClick={this.handleOTPLogin}
                  >
                    Confirm
                  </button>
                  {/* <small
                    style={{
                      padding: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    OR
                  </small>
                  <button
                    className="login-button"
                    style={{
                      background: "#eff2f7",
                      color: "black",
                      borderStyle: "none"
                    }}
                    // onClick={event => {
                    //   event.preventDefault();
                    //   this.setState({
                    //     // inputFlag: !this.state.inputFlag,
                    //     // otpSent: false
                    //     // email: "Enter Email Again",

                    //     confirmEmailFlag: false,
                    //     loginViewFlag: true,
                    //     email: ""
                    //   });
                    // }}
                    onClick={this.props.backToLoginForm}
                  >
                    Back
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConfirmOTPEmailView;
