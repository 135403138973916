import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import React, { Component, lazy, Suspense } from "react";
import Loader from "./components/shared/widgets/Loader";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./components/pages/auth/Login";
import LoginPage from "./components/pages/auth/login-fragments/LoginPage";
import Registration from "./components/pages/auth/Registration";
import Register from "./components/pages/auth/Register";
import ForgotPassword from "./components/pages/auth/forgot-password/ForgotPassword"
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
const LazyDashboardNavbar = lazy(() => import("./components/DashboardNavbar"));
const LazyCareerPage = lazy(() =>
  import("./components/pages/career-page/CareerPage")
);
const LazyReports = lazy(()=> import("./components/pages/reports/Report"))
const LazyPostedJobView = lazy(() =>
  import("./components/pages/posted-jobs/PostedJobView")
);

// const LazyLogin = lazy(() => import("./components/auth/Login"));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

function App() {
  const NoMatch = ({ location }) => (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }}
    >
      404. That’s an error. The requested URL was not found on this server.
    </div>
  );
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<Loader></Loader>}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/login-page" component={LoginPage} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              {/* <Route exact path="/register" component={Register} /> */}
              <Route
                exact
                path="/posted-job-view/:job_id"
                component={LazyPostedJobView}
              />
              <Route exact path="/register/:token" component={Register} />
              {/* <Route exact path="/registration" component={LazyRegistration} /> */}
              <PrivateRoute
                exact
                path="/jobs"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings"
                component={LazyDashboardNavbar}
              />
              <Route
                exact
                path="/:company_id/careers"
                component={LazyCareerPage}
              />
              <PrivateRoute
                exact
                path="/employeeHome"
                component={LazyDashboardNavbar}
              />

              <PrivateRoute
                exact
                path="/settings/manageTeams"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/manageUsers"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/manageAgencies"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/manageRecruiters"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/manageEmployees"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/manageJob"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/editeventtype"
                component={LazyDashboardNavbar}
              />

              <PrivateRoute
                exact
                path="/settings/customized_Qualification"
                component={LazyDashboardNavbar}
              />

              <PrivateRoute
                exact
                path="/settings/company"
                component={LazyDashboardNavbar}
              />

              <PrivateRoute
                exact
                path="/settings/candidate"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/settings/subscriptionSetting"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/notifications"
                component={LazyDashboardNavbar}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                component={LazyDashboardNavbar}
              />
               <PrivateRoute
                exact
                path="/settings/screening_questions"
                component={LazyDashboardNavbar}
              />

              <Route component={NoMatch} />
            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
