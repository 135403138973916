import LocalizedStrings from "react-localization";

export let strings = new LocalizedStrings({
  en: {
    COUNTRY: "Country",
    LOGO: "Logo",
    WEBSITE: "Website",
    USERNAME: "Username",
    HR_PORTAL: "The Recruiter",
    HR_PORTAL_MOTO: "Your one stop solution for all your HR needs",
    NIMAP_INFOTECH: "Nimap Infotech",
    WELCOME_BACK: "Welcome Back",
    NOT_A_MEMBER: "Click here to create new account",
    LOG_IN_GOOGLE: "Login with Google",
    LOG_IN_WITH_PASSWORD: "Login With Password",
    YOUR_SETTINGS: "Your Settings",
    COMPANY_SETTINGS: "Company Settings",
    CANDIDATE_SETTINGS: "Candidate Settings",
    NAME: "Name",
    COUNTRY: "Country",
    WEBSITE: "Website",
    LOGO: "Logo",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL_ID: "Your Email",
    PHONE: "Phone Number",
    SKILLS: "Skills",
    RITW: "Recruiter In Touch With",
    NOTICE: "Notice Period",
    CITY: "City",
    COVER_LETTER: "Cover Letter",
    ADD_COVER_LETTER: "Add Cover Letter",
    COVER_LETTER_ERROR: " didn'd add a cover letter",
    JOB_PROFILE: "Job Profile",
    JOB_MENU: "Job Menu",
    ADD_NEW_JOB: "Add New Job",
    JOBS: "Jobs",
    INBOX: "Inbox",
    CALENDER: "Calender",
    CAREER_PAGE: "Career Page",
    NOTIFICATION: "Notification",
    EMPLOYEE_PAGE: "Home",
    REPORT: "Report",
    DASHBOARD: "Dashboard",
    PASSWORD: "Password",
    SUBMIT: "Submit",
    VERIFY: "Verify",
    CURRENT_PASSWORD: "Current Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    FORGOT_PASSWORD: "Forgot Your Password",
    SAVE_CHANGES: "Save Changes",
    STATUS: "Status",
    GENERAL: "General",
    CALENDER_FIELD: "Calender Field",
    COMPANY_AND_TEAMS: "Company And Teams",
    OTHER_SETTINGS: "Other settings",
    COMPANY_NAME: "Company Name",
    COMPANY_SETTINGS: "Company Settings",
    MANAGE_USERS: "Manage Users",
    MANAGE_TEAMS: "Manage Teams",
    MANAGE_AGENCIES: "Manage Agency",
    EDIT_EVENT_TYPES: "Edit Event Types",
    TEMPLATE: "Template",
    JOB_TEMPLATE: "Job Template",
    JOB_OPENINGS: "Job Openings",
    EMAIL_TEMPLATE: "Email Templates",
    PIPELINE_TEMPLATE: "Pipeline Templates",
    OTHERS: "Others",
    BILLING: "Billing",
    ADD_NEW_USER_PLUS: "+ Add New User",
    ADD_NEW_USER: "Add New User",
    USER_DETAILS: " View User Details",
    UPDATE_USER: "Edit User",
    ADD_NEW_TEAM: "Add New Team",
    ADD_TEAM_NAME: "Add Team Name",
    ADD_MEMBER: "Add Member",
    TEAM_NAME: "Team Name",
    AGENCY_NAME: "Agency Name",
    SELECT_TEAM_MEMBERS: "Select Team Members",
    SELECT_AGENCY_MEMBERS: "Select Agency Members",
    ADD_AGENCY_NAME: "Add Agency Name",
    ALL_MEMBERS_ADDED: "All members are added!",
    ADD_CANDIDATE: "Add Candidate",
    ALL_CANDIDATES: "All Candidates",
    NEW_CANDIDATE: "New Candidate",
    CLOSE: "Close",
    SAVE: "Save",
    CANCEL: "Cancel",
    DELETE: "Delete",
    EDIT: "Edit",
    INVITE: "Invite",
    VIEW: "View",
    DONE: "Done",
    Download: "Download",
    Export:"Export",
    TYPE: "Type",
    CHOICE: "Choice",
    ADD_CHOICE: "Add Choice",
    QUESTION: "Question",
    UPLOAD_CSV: "Upload candidates data at once with csv file.",
    LOG_OUT: "SIGN OUT",
    LOG_IN: "Login",
    LOG_IN_OTP: "Login with OTP",
    USERS_NOT_AVAILABLE: "No users data found.",
    REGISTER_NEW_ACCOUNT: "Register new User",
    JOB_OPENINGS: "Want to join our team? We have {0} open jobs",
    JOBS_AT_COMPANY: "Jobs at Nimap Infotech",
    JOBS_AT: "Jobs at ",
    JOB_TITLE: "Job Title",
    CURRENT_JOBS_AT_COMPANY: "Current Jobs at Nimap Infotech",
    OPEN_JOBS: "{0} open jobs",
    MORE_INFO: "More ",
    LOADING: "Loading..",
    EDIT_STAGES: "Edit Stages",
    NO_STAGE: "No Stage",
    VIEW_POSTED_JOBS: " View all jobs at ",
    APPLY_NOW: "Apply Now",
    EXTENSION_TYPES: "accept = '.docs .docx .odt .pdf .doc'",
    CV_RESUME: "CV / Resume",
    SCREENING_QUESTIONS: "Screening Questions",
    CONTINUE: "Continue",
    NO_CANDIDATE_APPY: "No candidates have applied yet.",
    CLICK_ON: "Click On",
    TO_ADD: "To Add",
    RESUME_ERROR: " didn'd upload any resume.",
    DETAILS: "Details",
    ANSWER_ERROR: "No answer provided by candidate",
    SCREENING_OPTIONS: "Screening Questions",
    TOAST_MOVING_CANDIDATE: "Moving candidate to other stage...",
    UPLOAD_CANDIDATE_CSV: "Upload candidates data at once with csv file.",
    RESEND_OTP: "Resend OTP",
    ADD_NEW_AGENCY: "Add New Agency",
    ADD_NEW_RECRUITER: "Add New Recruiter",
    JOB_DETAILS: "Job Details",
    ENTER_OTP: "Enter OTP",
    PROFILEURL: "Linkedin Profile URL",
    PREVIOUS_EMPLOYEMENT: "Previous Employment",
    EDUCATION_QUALIFICATION: "Education Qualification",
    LINKDINURL: "Linkdin URL",
    REFERREDBY: "Referred By",
    QUALIFICATIONS: "Qualifications",
    MANAGE_RECRUITER: "Manage Recruiter",
    MANAGE_SCREENING_QUESTIONS: "Manage Screening Questions",
    ADD_NEW_QUESTION: "Add New Question",
    UPDATE_QUESTION: "Update Question",
    JOBS_REPORT: "Jobs Report",
    AGENCY_REPORT: "Agency Report",
    RECRUITER_REPORT: "Recruiter Report",
    JOB_STATUS_REPORT: "Job Status Report",
    USERS_REPORT: "Users Report",
    CANDIDATE_EXISTANCE_PERIOD: "Candidate Existance Period",
    PERIOD_TYPE: "Period Type",
    PERIOD_AMOUNT: "Period Amount",
  },
  it: {
    how: "Come vuoi il tuo uovo oggi?",
    boiledEgg: "Uovo sodo",
    softBoiledEgg: "Uovo alla coque",
    choice: "Come scegliere l'uovo",
  },
});
