import React, { Component } from "react";
import "../../../css/loader.css";

export default class Loader extends Component {
  render() {
    return (
      <div className="loader-container">
        <div className="item-1" />
        <div className="item-2" />
        <div className="item-3" />
        <div className="item-4" />
        <div className="item-5" />
      </div>
    );
  }
}
