import React, { Component } from "react";
import "../../../css/login.css";
import { strings } from "../../../resources/GlobalStrings";
import { BASE_URL } from "../../../resources/APIEndpoints";
import { USERS } from "../../../resources/APIEndpoints";
import cogoToast from "cogo-toast";
import APIService from "../../../services/APIService";
import SimpleReactValidator from "simple-react-validator";

class Register extends Component {
  validator = new SimpleReactValidator();
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    passError: "",
    jobTitle: "",
    token: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    localStorage.clear();
    const token = this.props.match.params.token;
    this.setState({
      token: token
    });
  }

  handelSubmit = async event => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
      return;
    }
    let isValid = this.state.password === this.state.rePassword;
    if (isValid) {
      let url = BASE_URL + USERS;
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        job_title: this.state.jobTitle,
        password: this.state.password,
        token: this.state.token
      };
      let response = await new APIService().post(url, data);
      if (response.error) {
        if (response.results === 400) {
        } else cogoToast.error(response.results.message);
      } else {
        // localStorage.removeItem("token");
        let path = "/";
        this.props.history.push(path);
      }
    } else {
      this.setState({ passError: "Password Does Not Match" });
    }
  };

  render() {
    return (
      <div className="login-body" style={{ height: "auto" }}>
        <div
            className="col-xl-6 col-lg-6 col-md-6"
            style={{ padding: "0", height: "100vh" }}
          >
            <div className="welcomebox">
              <div className="contentbox">
                <div className="company-label">
                  <img
                    src="/images/logo-06.png"
                    alt="Logo"
                    style={{ width: "85%" }}
                  />
                </div>
              </div>
              <div
                className="tagline-container"
                style={{ width: "100%", bottom: "0%" }}
              >
                <div className="tagline" style={{ padding: "2%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "80%",
                      fontWeight: "bold"
                    }}
                  >
                    "Your one stop solutions for all your recruiter needs"
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div
          className="col-lg-6 col-md-6 col-sm-12 col-12"
          style={{padding: "10px", height:'100vh',overflowY:'auto'}}
        >
          
              <div className="headingRegisteration">
                <div className="company-logo" style={{textAlign:'center'}}>
                  <img
                    id="nimap-logo"
                    src="/images/nimap-logo1.png"
                    alt="logo"
                  />
                </div>

                <div className="company-name" style={{textAlign:'center'}}>{strings.NIMAP_INFOTECH}</div>
              </div>
              {/* <p id="registrationText">{strings.REGISTER_NEW_ACCOUNT}</p> */}

              {this.state.errorMessage ? (
                <div
                  style={{ fontSize: 16, color: "red", textAlign: "center" }}
                >
                  {this.state.errorMessage}
                </div>
              ) : (
                ""
              )}
              <form id="registrationForm" onSubmit={this.handelSubmit}>
                <div className="inputbox">
                  <div className="inputText">{strings.FIRST_NAME}</div>

                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "First Name",
                      this.state.firstName,
                      "required"
                    )}
                  </div>
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.LAST_NAME}</div>

                  <input
                    type="text"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    name="lastName"
                    onChange={this.handleChange}
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Last Name",
                      this.state.lastName,
                      "required"
                    )}
                  </div>
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.EMAIL_ID} </div>

                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={this.state.email.value}
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Email Id",
                      this.state.email,
                      "required|email"
                    )}
                  </div>
                </div>

                <div className="inputbox">
                  <div className="inputText">{strings.JOB_TITLE}</div>

                  <input
                    type="text"
                    placeholder="Job title"
                    name="jobTitle"
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Job Title",
                      this.state.jobTitle,
                      "required"
                    )}
                  </div>
                </div>
                <div className="inputbox">
                  <div className="inputText">Password</div>

                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Password",
                      this.state.password,
                      "required"
                    )}
                  </div>
                </div>
                <div className="inputbox">
                  <div className="inputText">Re-Enter Password</div>

                  <input
                    type="password"
                    placeholder="Re-Enter Password"
                    name="rePassword"
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "Re-Enter Password",
                      this.state.rePassword,
                      "required"
                    )}
                  </div>
                </div>

                {this.state.passError && (
                  <div
                    style={{
                      fontSize: "15px",
                      margin: "0px",
                      paddingLeft: "15px",
                      color: "red"
                    }}
                  >
                    {this.state.passError}
                  </div>
                )}

                <button
                  id="login-button"
                  onClick={this.handelSubmit}
                  type="submit"
                  className='login-button'
                >
                  {strings.SUBMIT}
                </button>
              </form>
        </div>
      </div>
    );
  }
}

export default Register;
