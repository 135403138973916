import React, {useState} from 'react'
import EnterOTP from "./EnterOTP"
import EnterEmail from "./EnterEmail"
import { BASE_URL, FORGOT_PASSWORD, SEND_MAIL, CHANGE_PASSWORD } from '../../../../resources/APIEndpoints'
import cogoToast from "cogo-toast";

function ForgotPassword(props) {
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')

    const handleSendOTP = async(email) =>{
      setEmail(email)
      let url = BASE_URL + FORGOT_PASSWORD + SEND_MAIL
      let data= {
        email: email
      }
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          status = response.status
          return response.json()
        })
        .then(res => {
          if (status === 200) {
              cogoToast.info(res.success);
              step == 1 && setStep(step+1)
          } else{
              cogoToast.error(res.error);
          }
        })
    }

    const handleVerify = async(data) =>{
      let isValid = data.password === data.confirmPassword
      let status;
      if(isValid){
          let sendData = {
            otp: data.otp,
            password: data.password,
            re_password: data.confirmPassword
          }
          //API INTEGRATION
          let url = BASE_URL + FORGOT_PASSWORD + CHANGE_PASSWORD
          fetch(url, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(sendData)
          })
            .then(response => {
              status = response.status
              return response.json()
            })
            .then(res => {
              if(res.status == 200){
                cogoToast.success(res.message)
                backToLogin()
              } else {
                cogoToast.error(res.error)
              }
            })
        } else {
            cogoToast.error("New Password and Confirm Password didn't match.")
        }
    }

    const backToLogin = () =>{
        props.history.push("/")
    }

    const backToEnterEmail = ()=>{
        setStep(step - 1)
    }

    return (
        <div className="login-body" style={{ height: "auto" }}>
          <div
            className="col-xl-6 col-lg-6 col-md-6"
            style={{ padding: "0", height: "100vh" }}
          >
            <div className="welcomebox">
              <div className="contentbox">
                <div className="company-label">
                  <img
                    src="/images/logo-06.png"
                    alt="Logo"
                    style={{ width: "85%" }}
                  />
                </div>
              </div>
              <div
                className="tagline-container"
                style={{ width: "100%", bottom: "0%" }}
              >
                <div className="tagline" style={{ padding: "2%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "80%",
                      fontWeight: "bold"
                    }}
                  >
                    "Your one stop solutions for all your recruiter needs"
                  </div>
                </div>
              </div>
            </div>
          </div>
            {step === 1 && 
                <EnterEmail 
                    handleSendOTP={handleSendOTP}
                    backToLogin={backToLogin}
                    email={email}
                />
            }
            {step === 2 && 
                <EnterOTP 
                    handleSendOTP={handleSendOTP}
                    backToEnterEmail={backToEnterEmail}
                    handleVerify={handleVerify}
                    email={email ? email : ""}
                />
            }
            {/* {step === 3 &&
                <NewPassword 
                    backToLogin={backToLogin}
                />
            } */}
        </div>
    )
}

export default ForgotPassword
