import cogoToast from "cogo-toast";
import React, { Component } from "react";

export default class APIService {
  constructor() {
    this.error = false;
    this.results = {};
  }

  /* Get headers for the request */
  getHeaders(type) {
    let headers = {};
    if (localStorage.getItem("token") !== null) {
      headers = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      };
    } else {
      headers = {
        Accept: "application/json"
      };
    }

    if (type !== "form-data") {
      let contentType = { "content-type": "application/json" };
      headers = { ...headers, ...contentType };
    }
    return headers;
  }

  getAttrName(key) {
    var parts = key.split("_");
    let newKey = "";
    for (let part in parts) {
      newKey =
        newKey +
        " " +
        parts[part].charAt(0).toUpperCase() +
        parts[part].slice(1);
    }
    return newKey;
  }

  getMessage(obj) {
    var data = obj;
    var msg = "";
    if (obj.constructor == Object) {
      for (var key in data) {
        // msg =
        //   msg + this.getAttrName(key) + " : " + this.getMessage(data[key]) + "";
        cogoToast.error(
          <div>
            <b>{this.getAttrName(key)}</b> : {this.getMessage(data[key])}
          </div>,
          {
            hideAfter: 4,
            onClick: hide => {
              hide();
            }
          }
        );
      }
    } else if (obj.constructor == Array) {
      for (var key in data) {
        msg = msg + data[key] + "\t\n";
      }
    } else {
      msg = msg + obj + "\t\n";
    }
    return String(msg);
  }
  /* Checking for errors if any */
  checkErrors(response) {
    this.error = true;
    // var message = "";
    switch (this.status) {
      case 400:
        this.getMessage(this.results);
        break;
      case 401:
        window.localStorage.clear();
        window.location.replace("/");
        break;
      case 403:
        this.results = {
          message: "Permission Denied"
        };
        break;
      case 404:
        this.results = {
          message: "Results not found."
        };
        break;
      default:
        this.results = {
          message: "Something went wrong"
        };
    }
  }

  /* GET method call with fetch */
  async get(url) {
    await fetch(url, {
      method: "GET",
      headers: this.getHeaders()
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* POST method call with fetch */
  async post(url, data, type) {
    // var dataToSend = data;
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    await fetch(url, {
      method: "POST",
      body: data,
      headers: this.getHeaders(type)
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (!(this.status === 201 || this.status === 200)) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      status: this.status,
      results: this.results
    };
  }

  /* PUT method call with fetch */
  async put(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    await fetch(url, {
      method: "PUT",
      body: data,
      headers: this.getHeaders(type)
    })
      .then(response => {
        this.status = response.status;

        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }

    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* PATCH method call with fetch */
  async patch(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    await fetch(url, {
      method: "PATCH",
      body: data,
      headers: this.getHeaders(type)
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* DELETE method call with fetch */
  async delete(url) {
    await fetch(url, {
      method: "DELETE",
      headers: this.getHeaders()
    })
      .then(response => {
        this.status = response.status;
        if (this.status !== 204) {
          return response.json();
        }
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 204) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* POST method call with fetch */
  async postAnonymous(url, data, type) {
    // var dataToSend = data;
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      }
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (!(this.status === 201 || this.status === 200)) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      status: this.status,
      results: this.results
    };
  }
}
