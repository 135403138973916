import {
  FETCH_TEAMS,
  FETCH_TEAM,
  ADD_TEAM,
  ADD_MEMBER,
  DELETE_MEMBER,
  ADD_TEAM_MODAL_TOGGLE,
  ADD_MEMBER_MODAL_TOGGLE,
  CHECKBOX_TOGGLE,
  DELETE_TEAM
} from "../../../../actions/types";
import cogoToast from "cogo-toast";

const initialState = {
  teams: [],
  checkedItemsMembers: new Map(),
  isAddTeamModalOpened: false,
  isAddMemberModalOpened: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAMS:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) cogoToast.error(response.results.message);
        return {
          state
        };
      } else {
        if (response.results.length >= 0) {
          return {
            ...state,
            teams: response.results
          };
        }
      }

    case FETCH_TEAM:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) cogoToast.error(response.results.message);
        return {
          state
        };
      } else {
        var updatedTeams = state.teams;
        for (var i = 0; i < updatedTeams.length; i++) {
          if (updatedTeams[i]["id"] === Number(response.results.id)) {
            updatedTeams[i].team_members = response.results.team_members;
          }
        }
        return {
          ...state,
          teams: updatedTeams
        };
      }

    case ADD_TEAM:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) {
          cogoToast.error(response.results.message);
        }
        document.getElementById("addTeam").style.backgroundColor = "#316ce2";
        document.getElementById("addTeam").value = "Save";
        document.getElementById("addTeam").removeAttribute("disabled");
        return { ...state };
      } else {
        cogoToast.success("New Team Successfully Added");
        state.teams.unshift(response.results.data);
        return {
          ...state,
          teams: state.teams,
          checkedItemsMembers: new Map(),
          isAddTeamModalOpened: !state.isAddTeamModalOpened
        };

        // this.setState({
        //   isAddTeamModalOpened: false,
        //   checkedItemsMembers: new Map()
        // });
      }

    case ADD_TEAM_MODAL_TOGGLE:
      return {
        ...state,
        isAddTeamModalOpened: !state.isAddTeamModalOpened
      };

    case ADD_MEMBER_MODAL_TOGGLE:
      return {
        ...state,
        isAddMemberModalOpened: !state.isAddMemberModalOpened
      };

    case CHECKBOX_TOGGLE:
      return {
        ...state,
        checkedItemsMembers: state.checkedItemsMembers.set(
          action.name,
          action.isChecked
        )
      };

    case ADD_MEMBER:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) cogoToast.error(response.results.message);
        document.getElementById("addMember").style.backgroundColor = "#316ce2";
        document.getElementById("addMember").value = "Save";
        document.getElementById("addMember").removeAttribute("disabled");
      } else {
        cogoToast.success("New member successfully added in the team.");
        var updatedTeams = state.teams;
        for (var i = 0; i < updatedTeams.length; i++) {
          if (updatedTeams[i]["id"] === Number(action.id)) {
            updatedTeams[i].team_members = response.results.data.team_members;
          }
        }
        return {
          ...state,
          checkedItemsMembers: new Map()
        };
      }

    case DELETE_MEMBER:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) cogoToast.error(response.results.message);
      } else {
        var teams = state.teams;
        cogoToast.success("Team Member Successfully Deleted");
        console.log(teams);
        for (var i = 0; i < teams.length; i++) {
          if (teams[i]["id"] === action.teamID) {
            teams[i].team_members = teams[i].team_members.filter(function(
              value,
              index,
              arr
            ) {
              return value.id !== action.userID;
            });
          }
        }
        console.log(teams);
        return {
          ...state,
          teams
        };
      }

    case DELETE_TEAM:
      var response = action.payload;
      if (response.error) {
        if (response.status !== 400) cogoToast.error(response.results.message);
      } else {
        cogoToast.success("Team Successfully Deleted");
        var teams = state.teams.filter(function(value, index, arr) {
          return value.id != action.id;
        });
      }
      return {
        ...state,
        teams
      };

    default:
      return state;
  }
}
