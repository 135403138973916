import React, { Component } from "react";
import "../../../css/login.css";
import { strings } from "../../../resources/GlobalStrings";
import {
  BASE_URL,
  COMPANIES,
  COUNTRIES,
  TIMEZONES
} from "../../../resources/APIEndpoints";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import APIService from "../../../services/APIService";
import {Link} from 'react-router-dom'
import cogoToast from "cogo-toast";
import Login from "./Login";
class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      companyName: "",
      // website: "",
      // countryName: "",
      // timezoneName: "",
      // logo: "",
      // logoName: "",
      // jobTitle: "",
      error: false,
      errorMessage: false,
      otpFlag: false,
      otp: "",
      token: "",
      name: "",
      // countries: [],
      // timezones: [],
      redirectLoginPage: false,
      signup:true
    };

    this.handleChange = this.handleChange.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.stateOfLogin = this.stateOfLogin.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeForName = e => {
    const re=/^[A-Za-z\b]+$/;
    if(e.target.value===''||re.test(e.target.value)){
    this.setState({ [e.target.name]: e.target.value });
    }
  };

  stateOfLogin = () => {
    return true;
  };

  // async componentDidMount() {
  //   fetch(BASE_URL + COUNTRIES)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.setState({ countries: data });
  //     });

  //   fetch(BASE_URL + TIMEZONES)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.setState({ timezones: data });
  //     });
  // }

  validate = () => {
    let errorMessage = "";
    if ( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) {
    } else {
      errorMessage = "Invalid email. Please enter a valid email.";
    }

    if (this.state.firstName === "" ||  !(/[a-zA-Z]*$/.test(this.state.firstName))) {
      errorMessage = "First name cannot be empty";
    } else if (this.state.lastName === "") {
      errorMessage = "Last name cannot be empty";
    } 

    if (errorMessage) {
      this.setState({ errorMessage });
      return false;
    }

    this.setState({ errorMessage });
    return true;
  };

  handelSubmit = async event => {
    event.preventDefault();
    var isvalid = this.validate();
    if (isvalid) {
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
      };
      let url = BASE_URL + COMPANIES + "verify";
      let response = await new APIService().post(url, data);
      if (response.error) {
        if (response.status !== 400) {
          cogoToast.error(response.results.message);
          this.setState({
            redirectLoginPage: true
          });
        }
      } else {
        // // cogoToast.success("Invites Send Successfully");
        // console.log(this.state.country)
        this.setState({ otpFlag: true });
      }
    }
  };
  closeOTPPopup = () => {
    this.setState({ otpFlag: false });
  };

  verifyOTP = async event => {
    event.preventDefault();
    let url = BASE_URL + COMPANIES;
    const formData = new FormData();
    // formData.append({ [event.target.name]: event.target.value })
    formData.append("first_name", this.state.firstName);
    formData.append("last_name", this.state.lastName);
    formData.append("email", this.state.email);
    formData.append("otp", this.state.otp);
    // formData.append("job_title", this.state.email);
    formData.append("password", this.state.password);
    formData.append("name", this.state.name);
    // formData.append("logo", this.state.logo);
    // formData.append("website", this.state.website);
    // formData.append("country", this.state.countryName);
    // formData.append("timezone", this.state.timezoneName);
    // formData.append("logo", this.state.logo, this.state.logoName);

    //var options ={content :formData}

    let response = await new APIService().post(url, formData, "form-data");
    if (response.error) {
      if (response.status !== 400) {
        cogoToast.error(response.results.message);
      }
      // this.setState({redirectLoginPage:true})
    } else {
      cogoToast.success("Successfully Created");
      this.props.history.push("/");
      // this.setState({redirectLoginPage:true})

      //  <Route exact path="/" component={Login} />
    }
  };

  // handleFile(e) {
  //   console.log(e.target.files[0]);
  //   if (
  //     e.target.files[0].type === "image/png" ||
  //     e.target.files[0].type === "image/jpg" ||
  //     e.target.files[0].type === "image/jpeg"
  //   ) {
  //     this.setState({
  //       logo: e.target.files[0],
  //       logoName: e.target.files[0].name
  //     });
  //   } else {
  //     cogoToast.error("Only .png, .jpg and .jpeg allowed");
  //     return;
  //   }
  // }

  // handleDropDownChangeCountry = e => {
  //   this.setState({
  //     countryName: e.target.value
  //   });
  // };

  // handleDropDownChangTimezone = e => {
  //   this.setState({
  //     timezoneName: e.target.value
  //   });
  // };

  stateOfLogin() {
    return this.state.redirectLoginPage;
  }

  backToLoginForm(){
    this.setState({
      signup:false
    })
  }
  render() {
    const OtpForm = (
      <>
        <Modal isOpen={this.state.otpFlag}>
          <div className="modal-header">
            <h5 id="header-text">
              {this.props.flag ? strings.ADD_CANDIDATE : this.props.jobTitle}
            </h5>
            <div style={{position:"absolute"}}>Send OTP On Your Email</div>
            <div className="close-icon" onClick={this.closeOTPPopup}>
              <img
                alt=""
                height="28px"
                width="28px"
                src="/images/close-icon.png"
              />
            </div>
          </div>
          <form onSubmit={this.verifyOTP} noValidate>
            <ModalBody>
              <div className="inputbox">
                <div className="inputText">{strings.ENTER_OTP}</div>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  name="otp"
                  value={this.state.otp}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <input
                className="submit-btn"
                id="addUser"
                type="submit"
                value="Confirm"
              >
                {/* {strings.SAVE} */}
              </input>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
    return (
      <div>
        {this.state.signup ?
      <div className="login-body" style={{ height: "auto" }}>
        <div
            className="col-xl-6 col-lg-6 col-md-6"
            style={{ padding: "0", height: "100vh" }}
          >
            <div className="welcomebox">
              <div className="contentbox">
                <div className="company-label">
                  <img
                    src="/images/logo-06.png"
                    alt="Logo"
                    style={{ width: "85%" }}
                  />
                </div>
              </div>
              <div
                className="tagline-container"
                style={{ width: "100%", bottom: "0%" }}
              >
                <div className="tagline" style={{ padding: "2%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "80%",
                      fontWeight: "bold"
                    }}
                  >
                    "Your one stop solutions for all your recruiter needs"
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div
          className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          style={{ padding: "10px", display: "flex",height:'100vh',overflowY:'auto' }}
        >
          <div className="back-button-container2">
            <Link to="/">
            <div className="back-button">
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: "150%", color: "grey" }}
              />
            </div>
            </Link>
          </div>

              
              <form id="registrationForm">
            
                <p style={{textAlign: "center",fontSize: "20px",fontWeight: "bold"}}>Sign Up for an Account</p>
                <div>
                  <div className="inputbox">
                    <div className="inputText">{strings.FIRST_NAME}</div>

                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChangeForName}
                      required
                      className="inputBlock"
                    />
                  </div>
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.LAST_NAME}</div>

                  <input
                    type="text"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    name="lastName"
                    onChange={this.handleChangeForName}
                    className="inputBlock"
                  />
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.EMAIL_ID} </div>

                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={this.state.email.value}
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.PASSWORD}</div>

                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                </div>

                <div className="inputbox">
                  <div className="inputText">{strings.COMPANY_NAME} </div>

                  <input
                    type="text"
                    placeholder="Company Name "
                    name="name"
                    value={this.state.name.value}
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                </div>
                {this.state.errorMessage ? (
                <div
                  style={{ fontSize: 16, color: "red", textAlign: "center" }}
                >
                  {this.state.errorMessage}
                </div>
              ) : (
                ""
              )}

                {/* <div className="inputbox">
                  <div className="inputText">{strings.JOB_TITLE}</div>

                  <input
                    type="text"
                    placeholder="Job title"
                    name="jobTitle"
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                </div>
                

                <div className="inputbox">
                  <div className="inputText">{strings.WEBSITE}</div>

                  <input
                    type="text"
                    placeholder="Website"
                    name="website"
                    // password={this.state.website.value}
                    onChange={this.handleChange}
                    required
                    className="inputBlock"
                  />
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.COUNTRY}</div>

                  <select
                    className="form-control input-box"
                    name="countryName"
                    onChange={this.handleDropDownChangeCountry}
                    value={this.state.countryName}
                    required
                    className="inputBlock"
                    style={{background:'#fff'}}
                  >
                    <option value="">Select Country</option>
                    {this.state.countries.map(data => (
                      <option key={data.id} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="inputbox">
                  <div className="inputText">Timezones</div>

                  <select
                    className="form-control input-box"
                    name="timezone"
                    onChange={this.handleDropDownChangTimezone}
                    value={this.state.timezoneName}
                    required
                    className="inputBlock"
                    style={{background:'#fff'}}
                  >
                    <option value="">Select Timezone</option>
                    {this.state.timezones.map(data => (
                      <option key={data.id} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="inputbox">
                  <div className="inputText">{strings.LOGO}</div>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={e => this.handleFile(e)}
                      accept=".png, .jpg, .jpeg"
                      required
                      className="inputBlock"

                      style={{border:'none'}}
                    />
                    <span className="uploadFile"></span>
                    <i className="resume-filename">{this.state.logoName}</i>
                </div> */}
                {this.state.otpFlag && OtpForm}
              <div>
                <Button  color="primary" id="login-button" className="login-button" onClick={this.handelSubmit}>
                  {strings.SUBMIT}
                </Button >
              </div>
              <p style={{fontSize:'20px',fontWeight:'bold',textAlign:'center',margin:'10px 0 0 0'}}>Already have an Account?</p>
              <div>
                <Link to="/">
                <button id="login-button"  className='login-button'>
                  Login
                </button>
                </Link>
              </div>
              </form>
          {/* <div className="col-lg-1 col-md-1" style={{ padding: "0" }}></div> */}
        </div>
      </div>:''}
      </div>
    );
  }
}

export default Registration;
