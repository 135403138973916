import{FETCH_USER_DATA} from '../actions/types';

const initialState={
    fetchUsersData:[]
}

export default function(state=initialState,action){

    switch(action.type){
        case FETCH_USER_DATA:
         return   {
            fetchUsersData:action.payload
            }
      

     

      
        default:
            return state;
    }
    

}