import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { strings } from "../../../../resources/GlobalStrings";

class Logins extends Component {
  state = { email: "", password: "" };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handelSubmit = event => {
    event.preventDefault();
    this.props.handelSubmit(this.state.email, this.state.password);
  };
  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-12 mt-5">
            <h3 className="text-center">Welcome</h3>
          </div>
          <div className="col-12 mt-3 mb-3">
            <div className="logginGoogleBox m-auto">
              <GoogleLogin
                clientId="637128140483-mnhs0sjrm2trsu59ekae0c6qp005mlms.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
              />
            </div>
          </div>
          {this.state.emailError ? (
            <div className="error">{this.state.emailError}</div>
          ) : (
            ""
          )}
          {this.state.passwordError ? (
            <div className="error">{this.state.passwordError}</div>
          ) : (
            ""
          )}
          <div className="col-12">
            {" "}
            //
            <div className="loginbox-bottom">
              <form action="" noValidate>
                <div className="inputbox" style={{ margin: "0px" }}>
                  <div className="inputText">{strings.EMAIL_ID} </div>

                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="inputBlock"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="inputbox" style={{ margin: "0px" }}>
                  <div className="inputText">{strings.PASSWORD}</div>

                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    className="inputBlock"
                    password={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div
                  className="inputbox"
                  style={{
                    display: "block",
                    marginTop: "10px",
                    marginBottom: "0px"
                  }}
                >
                  <button className="login-button" onClick={this.handelSubmit}>
                    {strings.LOG_IN}
                  </button>
                  <div
                    style={{
                      padding: "10px",
                      alignItems: "center",
                      // display: "flex"
                      textAlign: "center"
                    }}
                  >
                    OR
                  </div>
                  <button
                    className="login-button"
                    onClick={this.props.loginOTPView}
                  >
                    {strings.LOG_IN_OTP}
                  </button>
                  <p className="signUpButton">
                    Do not have an account ?{" "}
                    <span
                      className="spanSignUpButton"
                      onClick={this.props.handelSignup}
                    >
                      Sign Up Now!
                    </span>
                  </p>
                </div>
                <div
                  className="companyName"
                  style={{ margin: "5px 0px 0px 0px" }}
                >
                  <p className="poweredBy" style={{ margin: "0px" }}>
                    Powered by <span className="comName">Nimap Infotech</span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Logins;
