import {SET_JOBS_PIPELINESTAGESDATA,FETCH_PIPELINE_STAGE,SET_JOBS_ACTIVEJOB,
    RESET_JOBS_ACTIVEJOB } from '../actions/types';

import cogoToast from "cogo-toast";
const initialState={
    pipelineStagesData:[],
    activeJobData:undefined
  
}

export default function(state=initialState,action){

    switch(action.type){
        case SET_JOBS_PIPELINESTAGESDATA:{
         
            return{ 
                ...state,
                pipelineStagesData:action.payload
               
            }
       
    }
    case FETCH_PIPELINE_STAGE:
        return{
            ...state,
            pipelineStagesData:action.payload.results

        }

    case SET_JOBS_ACTIVEJOB:
        return{
            ...state,
            activeJobData:action.payload
        } 
    case RESET_JOBS_ACTIVEJOB:
        return{
            ...state,
            activeJobData:action.payload 
        }   
       default:
            return state;
    }
    

}