import{FETCH_REPORT_DATA} from '../actions/types';

const initialState={
    reportList:[]
}

export default function(state=initialState,action){

    switch(action.type){
 
        case FETCH_REPORT_DATA:{
            return{ 
                ...state,
                reportList:action.payload
            }
        }

      
        default:
            return state;
    }
    

}