import React, { Component } from "react";
import "../../../../css/login.css";
import SimpleReactValidator from "simple-react-validator";

class EnterOTP extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        otp: {
          message: "The :attribute must be :values digits",
          rule: (val, params, validator) => {
            return val.toString().length === Number(params);
          },
          messageReplace: (message, params) =>
            message.replace(
              ":values",
              this.validator.helpers.toSentence(params)
            ),
          required: true
        }
      }
    });
    this.state = {
       otp: "",
       password: "",
       confirmPassword: "",
       showPassword: false,
       showConfirmPassword: false
      };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleVerify = event => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.props.handleVerify(this.state);
  };

  handleSendOTP = event => {
    event.preventDefault();
    if (this.props.email !== "") {
      this.props.handleSendOTP(this.props.email);
    }
  };

  toggleShowPassword = () =>{
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  toggleConfirmPassword = () =>{
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }

  checkLength = (e) =>{
    if(e.target.value.length == 4){
      e.preventDefault()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{
            margin: "auto 0",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className="back-button-container">
            <div
              className="back-button"
              onClick={this.props.backToEnterEmail}
            >
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: "150%", color: "grey" }}
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-12 loginbox">
            <div className="loginbox-bottom">
              <form action="" noValidate>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}
                >
                  PLEASE ENTER OTP & New Password
                </div>

                <div
                  className="validation-message"
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "gray"
                  }}
                >
                  The validation code (OTP) that get from email
                </div>
                <div className="inputbox hideArrows" style={{marginBottom:'0px'}}>
                  <div className="inputText">Enter OTP</div>
                  <input
                    type="number"
                    placeholder="OTP"
                    name="otp"
                    className="inputBlock"
                    value={this.state.otp}
                    onChange={this.handleChange}
                    onKeyPress={this.checkLength}
                    required
                  />
                  <div className="validation-message">
                    {this.validator.message(
                      "otp", 
                      this.state.otp, 
                      "required|numeric|otp:4"
                      )}
                  </div>
                </div>
                <div className="inputbox" style={{marginBottom:'0px'}}>
                  <div className="inputText">New Password</div>
                  <div className="customBlock" style={{display:"flex"}}>
                    <input
                      type={this.state.showPassword ? "text" : "password" }
                      placeholder="Password"
                      name="password"
                      style={{padding:"0px"}}
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <span>
                      <img 
                        className="customSvg" 
                        style={{height:"18px"}} 
                        src={this.state.showPassword ? "/images/hidden-eye.png" : "/images/eye.png" }
                        onClick={this.toggleShowPassword}
                      />
                    </span>
                  </div>
                  <div className="validation-message">
                    {this.validator.message(
                      "New Password",
                      this.state.password,
                      "required|max:15"
                    )}
                  </div>
                </div>
                <div className="inputbox" style={{marginBottom:'0px'}}>
                  <div className="inputText">Confirm Password</div>
                  <div className="customBlock" style={{display:"flex"}}>
                    <input
                      type={this.state.showConfirmPassword ? "text" : "password" }
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      className="inputBlock"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      required
                    />
                    <span>
                      <img 
                        className="customSvg" 
                        style={{height:"18px"}} 
                        src={this.state.showConfirmPassword ? "/images/hidden-eye.png" : "/images/eye.png" }
                        onClick={this.toggleConfirmPassword}
                      />
                    </span>
                  </div>
                  <div className="validation-message">
                    {this.validator.message(
                      "Confirm Password",
                      this.state.confirmPassword,
                      "required|max:15"
                    )}
                  </div>
                </div>
                <div className="inputbox" style={{ display: "flex", marginBottom: '0px' }}>
                  <button className="login-button" onClick={this.handleVerify}>
                    Change Password
                  </button>
                </div>
                <div className="resendOtp">
                  <p>
                    Don't get OTP?{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#316ce2",
                        cursor: "pointer"
                      }}
                      onClick={this.handleSendOTP}
                    >
                      RESEND
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EnterOTP;
