import { combineReducers } from "redux";
import postReducer from "./postReducer";
import manageTeamsReducer from "../components/pages/settings/teams/manageTeamsReducer";
import manageUserReducer from "./manageUserReducer";
import editPipelineReducer from "./editPipelineReducer";
import reportReducer from "./reportReducer";
import addUserReducer from "./addUserReducer";
import jobsReducer from "./jobsReducer";
import stageReducer from "./stageReducer";
import logineReducer from "./loginReducer";

export default combineReducers({
  notificationDetails: postReducer,
  piplineStagesData: postReducer,
  manageTeams: manageTeamsReducer,
  fetchManageUser: manageUserReducer,
  fetchManageUserData: manageUserReducer,
  fetchEditPiplineData: editPipelineReducer,
  reportData: reportReducer,
  addUserData: addUserReducer,
  fetchJobsData: jobsReducer,
  fetchPiplineFromStages: stageReducer,
  login: logineReducer
});
