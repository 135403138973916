import React, { Component } from "react";
import { strings } from "../../../../resources/GlobalStrings";
import "../../../../css/login.css";
import SimpleReactValidator from "simple-react-validator";

class EnterOTPView extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = { otp: "" };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  checkLength = (e) => {
    if (e.target.value.length == 6) {
      e.preventDefault()
    }
  }

  handleVerify = event => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.props.handleVerify(this.state.otp);
  };

  handleOTPLogin = event => {
    event.preventDefault();
    console.log(this.props.email);
    if (this.props.email !== "") {
      this.props.handleOTPLogin(this.props.email);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{
            margin: "auto 0",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className="back-button-container">
            <div
              className="back-button"
              onClick={this.props.backToConfirmEmail}
            >
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: "150%", color: "grey" }}
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-12 loginbox">
            {/* <div className="loginbox-middle">
                <hr className="hr-text" data-content="OR LOGIN WITH EMAIL" />
              </div> */}
            <div className="loginbox-bottom">
              <form action="" noValidate>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}
                >
                  PLEASE ENTER OTP
                </div>

                <div
                  className="validation-message"
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "gray"
                  }}
                >
                  The validation code that get from email
                </div>
                <div className="inputbox">
                  <div className="inputText">Enter OTP </div>

                  <input
                    type="number"
                    placeholder="OTP"
                    name="otp"
                    className="inputBlock"
                    value={this.state.otp}
                    onChange={this.handleChange}
                    required
                    onKeyPress={this.state.otp ? this.checkLength : undefined}
                  />
                  <div className="validation-message">
                    {this.validator.message("otp", this.state.otp, "required")}
                  </div>
                </div>
                <div className="inputbox" style={{ display: "flex" }}>
                  <button className="login-button" onClick={this.handleVerify}>
                    Verify
                  </button>
                  {/* <small
                    style={{
                      padding: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    OR
                  </small>
                  <button
                    className="login-button"
                    style={{
                      background: "#eff2f7",
                      color: "black",
                      borderStyle: "none"
                    }}
                    onClick={this.props.backToConfirmEmail}
                  >
                    Back
                  </button> */}
                </div>
                <div className="resendOtp">
                  <p>
                    Don't get OTP?{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#316ce2",
                        cursor: "pointer"
                      }}
                      onClick={this.handleOTPLogin}
                    >
                      RESEND
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EnterOTPView;
