import {
  SET_IS_SUBSCRIBED,
  SET_SUBSCRIPTION_TYPE,
  SET_ADDJOB_BUTTON_TRUE,
  SET_ADDJOB_BUTTON_FALSE
} from "../actions/types";

const initialState = {
  IS_SUBSCRIBED: true,
  showAddJobButton: true
  // subscription_type: "TRIAL"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_IS_SUBSCRIBED:
      return {
        ...state,
        IS_SUBSCRIBED: !state.IS_SUBSCRIBED
      };

    case SET_ADDJOB_BUTTON_TRUE:
      return {
        ...state,
        showAddJobButton: true
      };
    case SET_ADDJOB_BUTTON_FALSE:
      return {
        ...state,
        showAddJobButton: false
      };

    // case SET_SUBSCRIPTION_TYPE:
    //   return {
    //     ...state,
    //     subscription_type: action.payload
    //   };

    default:
      return state;
  }
}
