import React, { Component } from "react";
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
   ///   console.log("Error"+error,"Information"+info)
      this.setState({ hasError: true });
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return( <div><div
        style={{
          height: "100vh",
          justifyContent: "center",
          display: "flex",
          alignItems: "center"
        }}
      >
        500. Something went wrong! Please 
        <a style={{justifyContent: "center",
           marginLeft:'2px',
          display: "flex",
          alignItems: "center"}}
           href="/" >
          Refresh
        </a>
       
      </div>
       </div>)
      }
      else 
   
      return this.props.children;
    }
  }

  export default ErrorBoundary;