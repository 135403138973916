export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_PIPELINE_STAGE = "FETCH_PIPELINE_STAGE";
export const FETCH_TEAMS = "FETCH_TEAMS";
export const FETCH_TEAM = "FETCH_TEAM";
export const ADD_TEAM = "ADD_TEAM";
export const ADD_MEMBER = "ADD_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const ADD_TEAM_MODAL_TOGGLE = "ADD_TEAM_MODAL_TOGGLE";
export const ADD_MEMBER_MODAL_TOGGLE = "ADD_MEMBER_MODAL_TOGGLE";
export const DELETE_TEAM = "DELETE_TEAM";
export const CHECKBOX_TOGGLE = "CHECKBOX_TOGGLE";
export const FETCH_MANAGEUSER_DATA = "FETCH_MANAGEUSER_DATA";
export const HANDLE_POPUP_TOGGLE = "HANDLE_POPUP_TOGGLE";
export const SUBMIT_MANAGEUSER_DATA = "SUBMIT_MANAGEUSER_DATA";
export const UPDATE_FETCH_MANAGERDATA = "UPDATE_FETCH_MANAGERDATA";
export const FETCH_REPORT_DATA = "FETCH_REPORT_DATA";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const SET_JOBS_PIPELINESTAGESDATA = "SET_JOBS_PIPELINESTAGESDATA";
export const SET_STAGES_PIPELINESTAGESDATA = "SET_STAGES_PIPELINESTAGESDATA";
export const SET_JOBS_ACTIVEJOB = "SET_JOBS_ACTIVEJOB";
export const SET_IS_SUBSCRIBED = "SET_IS_SUBSCRIBED";
export const SET_SUBSCRIPTION_TYPE = "SET_SUBSCRIPTION_TYPE";
export const RESET_JOBS_ACTIVEJOB = "RESET_JOBS_ACTIVEJOB";
export const SET_ADDJOB_BUTTON_TRUE = "SET_ADDJOB_BUTTON_TRUE";
export const SET_ADDJOB_BUTTON_FALSE = "SET_ADDJOB_BUTTON_FALSE";
