/*
    API EndPoints are registered here.
    Changes them according to the need
*/
export let BASE_URL = window.API_URL;
export let USERS = "users/";   
export let USER = "user/";   
export let EXPORTRESUMES7D = "downloadfile_last_7_day/"; 
export let EXPORTRESUMES30D = "downloadfile_last_30_day/";
export let EXPORTRESUMES90D = "downloadfile_last_90_day/";        
export let EMPLOYEES = "employees/";
export let PASSWORD = "/password";
export let TEAMS = "teams/";
export let JOBPROFILES = "jobprofiles/";
export let SKILLSDATA = "skills_data/";
export let AGENCIES = "agencies/";
export let RECRUITERLIST = "recruiter/list";
export let RECRUITERS = "recruiter/";
export let CANDIDATES = "candidates/";
export let CANDIDATE = "candidate/";
export let STATUS = "status/";
export let PIPELINES = "pipelines/";
export let MEMBERS = "members/";
export let QUESTIONS = "questions/";
export let AUTH = "auth/";
export let LOGIN = "login";
export let VERIFY_USER = "auth/verify/user/";
export let VERIFY_CANDIDATE = "auth/verify/candidate/";
export let VERIFY = "verify/";
export let OTP = "otp/";
export let COUNTRIES = "locations/countries";
export let TIMEZONES = "locations/timezones";
export let STATES = "locations/states?country_id=";
export let CITIES = "locations/cities?state_id=";
export let INVITES = "invites";
export let COMPANIES = "companies/";
export let CANDIDATECOMPANYPERIOD = "candidate-company-period/";
export let SUBSCRIPTION = "subscriptions/";
export let REDEEM = "redeem";
export let NOTIFICATIONS = "notifications/";
export let COUNT = "count";
export let READ = "read";
export let EVENT = "event/";
export let TYPE = "type";
export let ATTENDEE = "attendee";
export let SKILLS = "skills/";
export let LOCATIONS = "locations/";
export let QUALIFICATIONS = "qualifications";
export let COMPANY='company/'
export let UPDATE_USER_PASSWORD='/password/change'
export let CHANGE_AGENCY_PASSWORD = 'change-agency-password/'
export let FORGOT_PASSWORD = 'forgotpassword/'
export let CHANGE_PASSWORD = 'changepassword/'
export let SEND_MAIL = 'send_mail/'

// ---------REPORTS APIS >> DASHBOARD-------------
export let JOB_PROFILES_REPORT='dashboard/job-profile-reports/?period=custom&start_date='
export let AGENCY_REPORT='dashboard/agency-reports/?period=custom&start_date='
export let RECRUITER_REPORT='dashboard/recruiter-reports/?period=custom&start_date='
export let JOB_STAGES_REPORT='dashboard/job-stages-reports/?period=custom&start_date=';
export let USER_REPORTS='dashboard/user-reports/?period=custom&start_date=';
export let CANDIDATE_GRAPH = "dashboard/candidate-graph/";
export let PACKAGE_GRAPH = "dashboard/candidate-package-graph/";

// --------------REPORTS DETAILS APIS-----------------
export let JOB_PROFILES_REPORT_DETAILS='dashboard/job-profile-reports-name-filter/?period=custom&start_date='
export let AGENCY_REPORT_DETAILS='dashboard/agency-reports-name-filter/?period=custom&start_date='
export let RECRUITER_REPORT_DETAILS='dashboard/recruiter-reports-name-filter/?period=custom&start_date='
export let JOB_STAGES_REPORT_DETAILS='dashboard/job-stages-reports-name-filter/?period=custom&start_date=';
export let USER_REPORTS_DETAILS='dashboard/user-reports-name-filter/?period=custom&start_date=';

// -----------------REPORTS MATRIX API-------------

export let JOB_PROFILE_REPORT_MATRIX='dashboard/job-report-matrix/?period=custom&start_date='
export let AGENCY_REPORT_MATRIX='dashboard/agency-report-matrix/?period=custom&start_date='
export let RECRUITER_REPORT_MATRIX='dashboard/recruiter-report-matrix/?period=custom&start_date='
export let USER_REPORT_MATRIX='dashboard/user-report-matrix/?period=custom&start_date='




// ---------------Screening questions apis----------------

export let SCREENING_QUES_LIST="screening-questions/"
export let DELETE_CHOICE = "screening-questions/choice/"



