import{FETCH_MANAGEUSER_DATA,HANDLE_POPUP_TOGGLE} from '../actions/types';
import cogoToast from "cogo-toast";
const initialState={
    usersData:[],
    modal:false,
    postResponseData:undefined,
    callbackInd:false
  
}

export default function(state=initialState,action){

    switch(action.type){
        case FETCH_MANAGEUSER_DATA:{
            if(action.payload.error){
                if(action.payload.status!==400 )
                    cogoToast.error(action.payload.results.message);
            }
            else{
            return{ 
                ...state,
                usersData:action.payload
            }
        }
    }

        case HANDLE_POPUP_TOGGLE:{
            return{ 
                ...state,
                modal:!state.modal
            }
        }

      
        default:
            return state;
    }
    

}