import React, { Component } from "react";
import "../../../css/login.css";
import GoogleLogin from "react-google-login";
import { BrowserRouter as Router } from "react-router-dom";
import { strings } from "../../../resources/GlobalStrings";
import cogoToast from "cogo-toast";
import {
  BASE_URL,
  AUTH,
  USER,
  LOGIN,
  VERIFY_USER,
  OTP
} from "../../../resources/APIEndpoints";
import SimpleReactValidator from "simple-react-validator";
import APIService from "../../../services/APIService";
import { connect } from "react-redux";
import {
  setIsSubscribed
  // setSubscriptionType
} from "../../../actions/loginAction";
// import ListGroup from "../../../node_modules/reactstrap/lib/ListGroup";
import EnterOTPView from "./login-fragments/EnterOTPView";
import ConfirmOTPEmailView from "./login-fragments/ConfirmOTPEmailView";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    if (
      window.localStorage.getItem("token") &&
      JSON.parse(window.localStorage.getItem("user")).user_type === "employee"
    ) {
      this.props.history.push("/employeeHome");
    } else if (window.localStorage.getItem("token")) {
      this.props.history.push("/jobs");
    }
    this.validator = new SimpleReactValidator({
      validators: {
        notRequired: {
          message: "If You Remember :attribute Then Click On Login",
          rule: (val, params, validator) => {
            return val.length === 0;
          }
        }
      }
    });

    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      isAuthenticated: false,
      inputFlag: false,
      otp: "",
      otpError: "",
      otpSent: false,
      loginWithOTP: false,
      showPassword: false,
      ///////////////////
      loginViewFlag: true,
      confirmEmailFlag: false,
      enterOTPFlag: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
  }


  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  validate = () => {
    let emailError = "";
    let passwordError = "";
    if (this.state.email === "") {
      emailError = "Email cannot be empty"
    }
    else {
      if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/.test(this.state.email)) {
      } else {
        emailError = "Invalid email. Please enter a valid email.";
      }
    }

    if (this.state.password === "") {
      passwordError = "Password cannot be empty";
    }

    if (emailError || passwordError) {
      this.setState({ emailError });
      this.setState({ passwordError });
      return false;
    }

    this.setState({ emailError });
    this.setState({ passwordError });
    return true;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleOTPLogin = async email => {
    this.setState({
      emailError: "",
      passwordError: "",
      otpError: "",
      otpSent: false,
      otp: "",
      email: email
    });

    var url = BASE_URL + VERIFY_USER + email;
    var response = await new APIService().get(url);
    if (response.error) {
      if (this.state.email !== "") {
        this.setState({
          otpError: "Given email address is not registered with us."
        });
      }
    } else {
      // console.log(response.error);
      cogoToast.info("OTP Has Sent To Your Associated Email ID");
      this.setState({
        inputFlag: !this.state.inputFlag,
        otpSent: !this.state.otpSent,
        confirmEmailFlag: false,
        enterOTPFlag: true
      });
    }
  };

  async fetchCompanyDetails(company_id) {
    let companyUrl = BASE_URL + "companies/" + company_id;
    var companyDetails = await new APIService().get(companyUrl);
    if (companyDetails.error) {
      if (companyDetails.status !== 400)
        cogoToast.error(companyDetails.results.message);
    } else {
      window.localStorage.setItem("companyLogo", companyDetails.results.logo);
      window.localStorage.setItem(
        "company",
        JSON.stringify(companyDetails.results)
      );
      // console.log("Company Fetch");
      if (companyDetails.results.is_subscribed === false) {
        // console.log("Set Subscribed");
        this.props.setIsSubscribed();
      }
      // console.log("data is");
      // console.log(companyDetails);

      // if (companyDetails.results.subscription_type !== "TRIAL") {
      //   this.props.setSubscriptionType(
      //     companyDetails.results.subscription_type
      //   );
      // }
      //companyDetails.results.subscription
      //Set subscription on store
      // window.IS_SUBSCRIBED = companyDetails.results.is_subscribed;
    }
  }

  doLogin(url, data, errorMessage) {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        const accessToken = res.access;

        if (
          res.status === 401 ||
          res.status === 403 ||
          // res.user === undefined ||
          accessToken === undefined
        ) {
          cogoToast.error(errorMessage);
        } else {
          this.setState({ isAuthenticated: !this.state.isAuthenticated });
          if (this.state.isAuthenticated) {
            window.localStorage.setItem("token", accessToken);
            window.localStorage.setItem("user", JSON.stringify(res.user));
            window.localStorage.setItem("id", res.user.id);
            window.localStorage.setItem("permissions", res.user.permissions);
            this.fetchCompanyDetails(res.user.company_id);
            let path =
              res.user.user_type === "employee" ? "./employeeHome" : "/jobs";
            this.props.history.push(path);
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleVerify = async otp => {
    // event.preventDefault();
    let data = {
      email: this.state.email,
      password: otp
    };
    let url = BASE_URL + AUTH + USER + OTP + LOGIN;
    let errorMessage = "Enter Valid OTP";
    this.doLogin(url, data, errorMessage);
  };

  handelSubmit = event => {
    event.preventDefault();
    this.setState({
      emailError: "",
      passwordError: "",
      otpError: "",
      otpSent: false
    });
    const isValid = this.validate();

    if (isValid) {
      fetch(BASE_URL + AUTH + USER + LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        })
      })
        .then(response => response.json())
        .then(res => {
          const accessToken = res.access;
          if (
            res.status === 401 ||
            res.status === 403 ||
            accessToken === undefined
          ) {
            this.setState({ emailError: "Invalid email or password." });
          } else {
            window.localStorage.setItem("token", accessToken);
            window.localStorage.setItem("user", JSON.stringify(res.user));
            window.localStorage.setItem("id", res.user.id);
            window.localStorage.setItem("permissions", res.user.permissions);
            window.localStorage.setItem("showAddJobButton", true);
            this.fetchCompanyDetails(res.user.company_id);
            this.setState({ isAuthenticated: !this.state.isAuthenticated });
            if (
              this.state.isAuthenticated &&
              res.user.user_type !== "employee"
            ) {
              let path = "/jobs";
              this.props.history.push(path);
            } else if (
              this.state.isAuthenticated &&
              res.user.user_type === "employee"
            ) {
              let path = "/employeeHome";
              this.props.history.push(path);
            }
          }
        })
        .catch(error => { });
    }
  };

  handelSignup() {
    let path = "/registration";
    this.props.history.push(path);
  }

  responseGoogle = async response => {
    if (!response.tokenId) {
      cogoToast.error(
        "Make sure that cookies are enabled on your browser to use login with google"
      );
      return;
    }
    let data = {
      token_id: response.tokenId
    };
    let url = BASE_URL + "auth/google";
    let errorMessage =
      "No active accound found. Please, Contact your organization to register your account.";
    this.doLogin(url, data, errorMessage);
  };

  loginOTPView = e => {
    e.preventDefault();
    this.setState({ loginViewFlag: false, confirmEmailFlag: true });
  };

  backToConfirmEmail = () => {
    this.setState({ confirmEmailFlag: true, enterOTPFlag: false });
  };
  backToLoginForm = () => {
    this.setState({ confirmEmailFlag: false, loginViewFlag: true });
  };

  render(e) {
    // console.log("Login Hit");
    var loginView = (
      <React.Fragment>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{ height: "100vh" }}
        >
          <div id="login-box">
            <div className="loginbox-top" style={{ position: "relative" }}>
              <div
                style={{
                  display: "block",
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <h3 style={{ marginBottom: "3%", marginTop: "5%" }}>Welcome</h3>
                <div
                  className="logginGoogleBox"
                  style={{
                    marginBottom: "3%",
                    width: "65%",
                    margin: "auto"
                  }}
                >
                  <GoogleLogin
                    clientId="352003010065-ojo4j7su6q986j9c2a6objk03fahbv3v.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                  />
                </div>
              </div>
            </div>


            <div className="col-lg-8 col-md-8 col-sm-12 col-12 loginbox">
              {/* <div className="loginbox-middle">
                <hr className="hr-text" data-content="OR LOGIN WITH EMAIL" />
              </div> */}
              <div className="loginbox-bottom">
                <form action="" noValidate>
                  <div className="inputbox" style={{ margin: "0px" }}>
                    <div className="inputText">{strings.EMAIL_ID} </div>

                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      className="inputBlock"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  {this.state.emailError ? (
                    <div className="error">{this.state.emailError}</div>
                  ) : (
                      ""
                    )}
                  <div className="inputbox" style={{ margin: "0px" }}>
                    <div className="inputText">{strings.PASSWORD}</div>

                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      className="inputBlock"
                      password={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <span>

                      <input type="checkbox"
                        id="showpassword"
                        checked={this.state.showPassword}
                        onClick={this.toggleShowPassword}
                      />
                      <label for="showpassword" className="checkBoxLabel">
                        Show Password
                       </label>
                    </span>

                    {this.state.passwordError ? (
                      <div className="error">{this.state.passwordError}</div>
                    ) : (
                        ""
                      )}
                  </div>


                  <div
                    className="inputbox"
                    style={{
                      display: "block",
                      marginTop: "10px",
                      marginBottom: "0px"
                    }}
                  >
                    <button
                      className="login-button"
                      onClick={this.handelSubmit}
                    >
                      {strings.LOG_IN}
                    </button>
                    <div
                      style={{
                        padding: "10px",
                        alignItems: "center",
                        // display: "flex"
                        textAlign: "center"
                      }}
                    >
                      OR
                    </div>
                    <button
                      className="login-button"
                      onClick={this.loginOTPView}
                    >
                      {strings.LOG_IN_OTP}
                    </button>
                    <p className="signUpButton">
                      Do not have an account ?{" "}
                      <span
                        className="spanSignUpButton"
                        onClick={this.handelSignup.bind(this)}
                      >
                        Sign Up Now!
                      </span>
                    </p>
                    <p className="signUpButton">
                      <span
                        className="spanSignUpButton"
                        onClick={() => this.props.history.replace("/forgot-password")}
                      >
                        Forgot Password?
                        </span>
                    </p>
                  </div>
                  <div
                    className="companyName"
                    style={{ margin: "5px 0px 0px 0px" }}
                  >
                    <p className="poweredBy" style={{ margin: "0px" }}>
                      Powered by <span className="comName">Nimap Infotech</span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <Router>
        <div className="login-body" style={{ height: "auto" }}>
          <div
            className="col-xl-6 col-lg-6 col-md-6"
            style={{ padding: "0", height: "100vh" }}
          >
            <div className="welcomebox">
              <div className="contentbox">
                <div className="company-label">
                  <img
                    src="/images/logo-06.png"
                    alt="Logo"
                    style={{ width: "85%" }}
                  />
                </div>
              </div>
              <div
                className="tagline-container"
                style={{ width: "100%", bottom: "0%" }}
              >
                <div className="tagline" style={{ padding: "2%" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "80%",
                      fontWeight: "bold"
                    }}
                  >
                    "Your one stop solutions for all your recruiter needs"
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-2 col-md-2" style={{ padding: "0" }}></div> */}

          {this.state.loginViewFlag && loginView}
          {this.state.confirmEmailFlag && (
            <ConfirmOTPEmailView
              handleOTPLogin={this.handleOTPLogin}
              backToLoginForm={this.backToLoginForm}
            />
          )}

          {this.state.enterOTPFlag && (
            <EnterOTPView
              handleVerify={this.handleVerify}
              backToConfirmEmail={this.backToConfirmEmail}
              handleOTPLogin={this.handleOTPLogin}
              email={this.state.email ? this.state.email : ""}
            />
          )}

          {/* <div className="col-lg-2 col-md-2" style={{ padding: "0" }}></div> */}
        </div>
      </Router>
    );
  }
}
const mapStateToProps = state => ({
  is_subscribed: state.login.IS_SUBSCRIBED //working
  // subscription_type: state.login.subscription_type //working
});

const mapDispatchToProps = dispatch => ({
  setIsSubscribed: () => dispatch(setIsSubscribed()) //working
  // setSubscriptionType: data => dispatch(setSubscriptionType(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
