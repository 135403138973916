import React, { Component } from "react";
import "../../../../css/login.css";

import cogoToast from "cogo-toast";
import {
  BASE_URL,
  AUTH,
  USER,
  LOGIN,
  VERIFY_USER,
  OTP
} from "../../../../resources/APIEndpoints";
import SimpleReactValidator from "simple-react-validator";
import APIService from "../../../../services/APIService";
import Logins from "./Logins";

class LoginPage extends Component {
  state = {
    loginViewFlag: true,
    confirmEmailFlag: false,
    enterOTPFlag: false,
    isAuthenticated: false,
    emailError: ""
  };

  handelSignup = () => {
    let path = "/registration";
    this.props.history.push(path);
  };

  loginOTPView = e => {
    e.preventDefault();
    this.setState({ loginViewFlag: false, confirmEmailFlag: true });
  };

  async fetchCompanyDetails(company_id) {
    let companyUrl = BASE_URL + "companies/" + company_id;
    var companyDetails = await new APIService().get(companyUrl);
    if (companyDetails.error) {
      if (companyDetails.status !== 400)
        cogoToast.error(companyDetails.results.message);
    } else {
      window.localStorage.setItem("companyLogo", companyDetails.results.logo);
      window.localStorage.setItem(
        "company",
        JSON.stringify(companyDetails.results)
      );

      if (companyDetails.results.is_subscribed === false) {
        this.props.setIsSubscribed();
      }
      console.log("data is");
      console.log(companyDetails);

      // if (companyDetails.results.subscription_type !== "TRIAL") {
      //   this.props.setSubscriptionType(
      //     companyDetails.results.subscription_type
      //   );
      // }
      //companyDetails.results.subscription
      //Set subscription on store
      // window.IS_SUBSCRIBED = companyDetails.results.is_subscribed;
    }
  }

  handelSubmit = (email, password) => {
    this.setState({
      emailError: "",
      passwordError: "",
      otpError: "",
      otpSent: false
    });
    fetch(BASE_URL + AUTH + USER + LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then(response => response.json())
      .then(res => {
        const accessToken = res.access;
        if (
          res.status === 401 ||
          res.status === 403 ||
          accessToken === undefined
        ) {
          this.setState({ emailError: "Invalid email or password." });
        } else {
          window.localStorage.setItem("token", accessToken);
          window.localStorage.setItem("user", JSON.stringify(res.user));
          window.localStorage.setItem("id", res.user.id);
          window.localStorage.setItem("permissions", res.user.permissions);
          this.fetchCompanyDetails(res.user.company_id);
          this.setState({ isAuthenticated: !this.state.isAuthenticated });
          if (this.state.isAuthenticated && res.user.user_type !== "employee") {
            let path = "/jobs";
            this.props.history.push(path);
          } else if (
            this.state.isAuthenticated &&
            res.user.user_type === "employee"
          ) {
            let path = "/employeeHome";
            this.props.history.push(path);
          }
        }
      })
      .catch(error => {});
  };
  render() {
    return (
      <div className="container-fluid" style={{ height: "auto" }}>
        <div className="row">
          <div className="welcomebox col-md-7 d-none d-md-block">
            {/* <div className="data-container border border-white">data</div> */}
          </div>
          <div className="col-md-5">
            <Logins
              handelSignup={this.handelSignup}
              loginOTPView={this.loginOTPView}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
