import{FETCH_POSTS,FETCH_PIPELINE_STAGE} from '../actions/types';

const initialState={
    items:[],
    item:{},
    piplineStages:[]
}

export default function(state=initialState,action){

    switch(action.type){
        case FETCH_POSTS:
            return{ 
                ...state,
                items:action.payload
            }
        case FETCH_PIPELINE_STAGE:
            return{
                ...state,
                piplineStages:action.payload.results

            }
        default:
            return state;
    }
    

}