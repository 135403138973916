import {SET_STAGES_PIPELINESTAGESDATA} from '../actions/types';

import cogoToast from "cogo-toast";
const initialState={
    pipelineStagesData:[]
  
}

export default function(state=initialState,action){

    switch(action.type){
        case SET_STAGES_PIPELINESTAGESDATA:{
         
            return{ 
                ...state,
                pipelineStagesData:action.payload
               
            }
       
    }
    default:
            return state;
   
    
    }
}