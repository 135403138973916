import {
  SET_IS_SUBSCRIBED,
  SET_SUBSCRIPTION_TYPE,
  SET_ADDJOB_BUTTON_TRUE,
  SET_ADDJOB_BUTTON_FALSE
} from "./types";

export const setIsSubscribed = () => ({
  type: SET_IS_SUBSCRIBED
});

export const setAddJobButtonTrue = () => ({
  type: SET_ADDJOB_BUTTON_TRUE
});

export const setAddJobButtonFalse = () => ({
  type: SET_ADDJOB_BUTTON_FALSE
});

// export const setSubscriptionType = data => ({
//   type: SET_SUBSCRIPTION_TYPE,
//   payload: SET_SUBSCRIPTION_TYPE
// });
