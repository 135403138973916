import{UPDATE_FETCH_MANAGERDATA} from '../actions/types';

const initialState={
    pipelineStageList:[]
   
  
}

export default function(state=initialState,action){

    switch(action.type){
 
        case UPDATE_FETCH_MANAGERDATA:{
            return{ 
                ...state,
                pipelineStageList:action.payload
            }
        }

      
        default:
            return state;
    }
    

}